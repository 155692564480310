
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from "react-router-dom";
import RouterNavigate from './RouterNavigate';
import "./assets/Styles/Main.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/css/App.css"


function App() {
  return (<>
    <ToastContainer />
    <Router>
      <RouterNavigate />
    </Router>
  </>
  )
}

export default App;

