import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../../src/assets/Styles/Blog.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { MdOutlineWatchLater } from "react-icons/md";
import { Link } from "react-router-dom";

import React from 'react';

const Blog = () => {
    const navigate = useNavigate()

    const [blogs, setBlogs] = useState([]);
    const [popular, setPopular] = useState([]);
    const [breakingNews, setBreakingNews] = useState([]);

    const LatestNews = blogs?.slice(1);


    const getAllBlogs = async () => {
        try {
            const response = await axios.get('https://resumecv.idea2reality.tech/api/get_blog');
            setBlogs(response?.data?.data);
            setPopular(response?.data?.popular);
            setBreakingNews(response?.data?.breaking_news);
        } catch (error) {
            console.error('Error fetching all blogs:', error.message);
        }
    };

    useEffect(() => {
        getAllBlogs();
    }, []);

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };


    return (
        <>
            {/* <div className='main-div-newsroom py-5'> extra */}
            <div className='container py-3'>
                <div className="news-section-3 py-4">
                    <div className="d-flex justify-content-between">
                        <h3>BREAKING NEWS</h3>
                        {breakingNews?.length > 2 ? <p>View All </p> : ''}
                    </div>

                    <Swiper
                        className=""
                        modules={[Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay={true}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                    >
                        {breakingNews?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <Link to={`/blog-details/${item?.id}`}>
                                    <div className="weekly-review">
                                        <img className="weekly-reviews-img"

                                            src={`https://resumecv.idea2reality.tech/storage/thumbnail_image/${blogs[0]?.thumbnail_image
                                                }`}
                                        />

                                        <div className="text-block">
                                            <span>{item?.category?.name}</span>
                                            <h3 className="news-text1">{item?.title}.</h3>
                                            <p className="m-0">Views: ({item?.views})</p>
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>


            <div className="container" style={{ marginBottom: '15%' }}>
                <h1>Articles</h1>
                <div className="border-bottom"></div>

                <div className="blog-parent py-5">
                    <div className="blog-child1">
                        <Link to={`/blog-details/${blogs[0]?.id}`}>
                            {/* <img src={`https://resumecv.idea2reality.tech/storage/thumbnail_image/${blogs[0]?.thumbnail_image
                            }`} /> */}
                            <div className="blog-overlay-text">
                                <p>Written by <span>{blogs[0]?.news_reporter}</span></p>
                                <h3><Link to={`/blog-details/${blogs[0]?.id}`} style={{ textDecoration: 'none', color: '#000' }}
                                    onMouseEnter={(e) => e.target.style.color = '#ff5733'}
                                    onMouseLeave={(e) => e.target.style.color = '#000'}>{blogs[0]?.title} </Link></h3>
                                <p className="d-flex gap-2"><MdOutlineWatchLater size={20} /> 29 min</p>
                            </div>

                            <div className='position-absolute' style={{ bottom: '15px', right: '15px' }}>
                                <img src="https://s3.resume.io/cdn-cgi/image/width=304,height=266,dpr=1.5,fit=contain,quality=100,format=auto/uploads/blog_post/featured_image/299/ResumeFormats.jpg" />
                            </div>
                        </Link>
                    </div>
                    <div className="blog-child2">
                        <h3>Popular</h3>

                        {popular?.map((item) => {
                            return (
                                <>
                                    <div>
                                        <img src='https://s3.resume.io/cdn-cgi/image/width=304,height=266,dpr=1.5,fit=contain,quality=100,format=auto/uploads/blog_post/featured_image/30877/list_skills_on_resume_-_featured_image.jpg' />
                                        <span className=''>{item?.category?.name}</span>
                                        <p>  <Link to={`/blog-details/${item?.id}`} style={{ textDecoration: 'none', color: '#000' }}
                                            onMouseEnter={(e) => e.target.style.color = '#ff5733'}
                                            onMouseLeave={(e) => e.target.style.color = '#000'}>{item?.title} </Link></p>
                                    </div>
                                </>
                            )
                        })}


                        {/* <div className='border-bottom mb-4'></div>

                        <div>
                            <img src='https://s3.resume.io/cdn-cgi/image/width=304,height=266,dpr=1.5,fit=contain,quality=100,format=auto/uploads/blog_post/featured_image/2134/How_to_Write_a_Resume_with_No_Experience.png' />
                            <span className=''>Resume Help</span>
                            <p>How to write a resume with no experience + (free) examples</p>
                        </div> */}

                    </div>
                    <div className="blog-child3 ">
                        {blogs?.length > 0 ? <h3>Latest</h3> : <h3>No Latest News</h3>}


                        {blogs?.map((item) => {
                            return (
                                <>
                                    <div>
                                        <span>{formatDate(item?.news_date)}</span>
                                        <Link to={`/blog-details/${item?.id}`}>
                                            <p dangerouslySetInnerHTML={{ __html: item?.summary }} style={{ textDecoration: 'none', color: '#000' }}
                                                onMouseEnter={(e) => e.target.style.color = '#ff5733'}
                                                onMouseLeave={(e) => e.target.style.color = '#000'}></p>
                                        </Link>
                                    </div>
                                </>
                            )
                        })}


                        <div className='border-bottom mb-4'></div>

                        <div>
                            <span>December 29, 2024</span>
                            <Link to={`/blog-details/${'1'}`}>
                                <p style={{ textDecoration: 'none', color: '#000' }}
                                    onMouseEnter={(e) => e.target.style.color = '#ff5733'}
                                    onMouseLeave={(e) => e.target.style.color = '#000'}>How to address a cover letter (real examples & tips)</p>
                            </Link>
                        </div>

                        {/* <div className='border-bottom mb-4'></div>

                        <div>
                            <span>08 Jan 2025</span>
                            <p>What to include in a cover letter</p>
                        </div>

                        <div className='border-bottom mb-4'></div> */}
                    </div>
                </div>
            </div >

        </>

    )
}
export default Blog;