
import React, { useState, useEffect } from "react";
import { Divider, MenuItem, Paper, Select, Button } from "@mui/material";
import { connect } from "react-redux";
import { ADDEDUCATION } from "../Redux/Actions";
import { useForm, Controller } from "react-hook-form";
import "../assets/Styles/EducationComp.css";
import BackNextBtnComp from "./BackNextBtnComp";
import InputComp from "./InputComp";
import SelectComp from "./SelectComp";

const currentYear = new Date().getFullYear();
const Years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => 1950 + i);


// const Years = [
//   "2025",
//   "2024",
//   "2023",
//   "2022",
//   "2021",
//   "2020",
//   "2019",
//   "2018",
//   "2017",
//   "2016",
//   "2015",
//   "2014",
//   "2013",
//   "2012",
//   "2011",
//   "2010",
//   "2009",
//   "2008",
//   "2007",
//   "2006",
//   "2005",
//   "2004",
//   "2003",
//   "2002",
//   "2001",
// ];

const mapStateToProp = (state) => ({
  educationInfo: state.EducationInfo.educationInfo, // Saved education data from Redux
});

const mapDispatchToProps = (dispatch) => ({
  onAddEducation: (details) => dispatch(ADDEDUCATION(details)),
});

const EducationComp = (props) => {
  const [loading, setLoading] = useState(false);
  const [educationList, setEducationList] = useState([
    { domain: "", university: "", degree: "", start_year: "", end_year: currentYear },
  ]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  // Initialize educationList with saved data from Redux (if available)
  useEffect(() => {
    if (props.educationInfo && props.educationInfo.length > 0) {
      setEducationList(props.educationInfo); // Set saved education data
    }
  }, [props.educationInfo]);

  const handleNext = (data) => {
    setLoading(true);
    props.onAddEducation(educationList);

    setTimeout(() => {
      setLoading(false);
      props.setTab(props.tab + 1); // Navigate to the next tab
    }, 1000);
  };

  const handleBack = () => {
    props.setTab(props.tab - 1);
  };

  // Handle adding a new education entry
  const addNewEducation = () => {
    setEducationList([
      ...educationList,
      { domain: "", university: "", degree: "", start_year: "", end_year: "" },
    ]);
  };

  // Handle changes to individual education entry
  const updateEducation = (index, key, value) => {
    const updatedList = educationList.map((education, i) =>
      i === index ? { ...education, [key]: value } : education
    );
    setEducationList(updatedList);
  };

  return (
    <Paper className="EducationPaper col-9" elevation={3}>
      <h2 className="education-heading">Education Details</h2>
      <Divider sx={{ margin: "10px 0px" }} />
      <form onSubmit={handleSubmit(handleNext)}>
        {educationList.map((education, index) => (
          <div key={index} className="education-form">
            <h4>Education {index + 1}</h4>
            <InputComp
              title={"Domain"}
              type={"text"}
              asterisk={true}
              name={`domain-${index}`}
              placeholder={"Domain"}
              register={register}
              multiline={false}
              value={education.domain}
              setValue={(value) => updateEducation(index, "domain", value)}
              error={errors[`domain-${index}`] ? true : false}
              errorMessage={
                errors[`domain-${index}`]
                  ? errors[`domain-${index}`].message
                  : null
              }
            />
            <InputComp
              title={"University"}
              type={"text"}
              asterisk={true}
              name={`university-${index}`}
              placeholder={"University"}
              register={register}
              multiline={false}
              value={education.university}
              setValue={(value) => updateEducation(index, "university", value)}
              error={errors[`university-${index}`] ? true : false}
              errorMessage={
                errors[`university-${index}`]
                  ? errors[`university-${index}`].message
                  : null
              }
            />
            <InputComp
              title={"Degree"}
              type={"text"}
              asterisk={true}
              name={`degree-${index}`}
              placeholder={"Degree"}
              register={register}
              multiline={false}
              value={education.degree}
              setValue={(value) => updateEducation(index, "degree", value)}
              error={errors[`degree-${index}`] ? true : false}
              errorMessage={
                errors[`degree-${index}`]
                  ? errors[`degree-${index}`].message
                  : null
              }
            />
            <SelectComp
              title={"Start Year"}
              asterisk={true}
              errorMessage={
                errors[`start_year-${index}`]
                  ? errors[`start_year-${index}`].message
                  : null
              }
              error={errors[`start_year-${index}`] ? true : false}
            >
              <Controller
                name={`start_year-${index}`}
                control={control}
                rules={{
                  required: "*Please Select Start Year",
                  validate: (value) =>
                    !value ||
                    value <= getValues(`end_year-${index}`) ||
                    "*Start year must be before or the same as end year",
                }}
                defaultValue={education.start_year}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={education.start_year}
                    onChange={(e) => {
                      updateEducation(index, "start_year", e.target.value);
                      field.onChange(e);
                    }}
                    error={!!errors[`start_year-${index}`]}>
                    <MenuItem value="" disabled>
                      Select a start year
                    </MenuItem>
                    {Years?.map((Year, idx) => (
                      <MenuItem key={idx} value={Year}>
                        {Year}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </SelectComp>
            <SelectComp
              title={"End Year"}
              asterisk={true}
              errorMessage={
                errors[`end_year-${index}`]
                  ? errors[`end_year-${index}`].message
                  : null
              }
              error={errors[`end_year-${index}`] ? true : false}>
              <Controller
                name={`end_year-${index}`}
                control={control}
                rules={{
                  required: "*Please Select End Year",
                  validate: (value) =>
                    !value ||
                    value >= getValues(`start_year-${index}`) ||
                    "*End year must be after or the same as start year",
                }}
                defaultValue={education.end_year}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={education.end_year}
                    onChange={(e) => {
                      updateEducation(index, "end_year", e.target.value);
                      field.onChange(e);
                    }}
                    error={!!errors[`end_year-${index}`]}
                  >
                    <MenuItem value="" disabled>
                      Select an end year
                    </MenuItem>
                    {Years?.map((Year, idx) => (
                      <MenuItem key={idx} value={Year}>
                        {Year}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </SelectComp>
            <Divider sx={{ margin: "10px 0px" }} />
          </div>
        ))}
        <Button
          variant="text"
          className="add-new-btn-cont"
          color="primary"
          onClick={addNewEducation}
          style={{ marginBottom: "20px" }}
        >
          Add New Education
        </Button>
        <BackNextBtnComp
          onNext={handleSubmit(handleNext)}
          onBack={handleBack}
          loading={loading}
          tab={props.tab}
          nextTitle={"Next"}
          backTitle={"Back"}
        />
      </form>
    </Paper>
  );
};

export default connect(mapStateToProp, mapDispatchToProps)(EducationComp);
