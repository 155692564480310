import React, { useEffect, useState } from "react";
import "../assets/Styles/MyResumes.css";
import { Button } from "@mui/material";
import DarkEffect from "../Components/DarkEffect";
import { templates } from "../Data/templates";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import JsPDF from "jspdf";
import { connect } from "react-redux";
import {
  ADDEXPERIENCEALL,
  ADDEDUCATION,
  ADDPERSONALINFO,
  EDITSKILL,
  SELECTRESUME,
  SELECTTEMPLATE,
} from "../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { getResume } from "../api/services/resumeService";
import DeleteResumeWarningMdl from "../Components/MyResume/DeleteResumeWarningMdl";
import { handleDeleteResume } from "../api/services/memberDashboardService";
import { toast } from "react-toastify";
import { Loader1 } from "../Components/Loader/Loader1";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: theme?.spacing(1),
}));

//For mapping state with props.
const mapStateToProps = (state) => ({
  selectedTemplateId: state?.selectedTemplateReducer?.selectedTemplateId,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id)),
  setSelectedResumeId: (id) => dispatch(SELECTRESUME(id)),
  onADDPERSONALINFO: (details) => dispatch(ADDPERSONALINFO(details)),
  setADDEXPERIENCEALL: (experiences) => dispatch(ADDEXPERIENCEALL(experiences)),
  onADDEDUCATION: (details) => dispatch(ADDEDUCATION(details)),
  onEDITSKILL: (skills) => dispatch(EDITSKILL(skills)),
});

const MyResumes = (props) => {
  const [resumes, setResumes] = useState([]);
  const [deleteDataMdl, setDeleteDataMdl] = useState(false);
  const [deleteResumeId, setDeleteResumeId] = useState("");
  const [loading,setLoading]=useState(false);

  //checking for available resumes in localStorage
  useEffect(() => {
    const newResumes = window.localStorage.getItem("resumes")
      ? JSON.parse(window.localStorage.getItem("resumes"))
      : [];
    // console.log("newResumes", newResumes);
    // setResumes(newResumes);
  }, []);

  const navigate = useNavigate();

  const getTemplate = (resume, index) => {
    let template = templates?.find(
      (eachTemplate) => eachTemplate?.id == resume?.template_id
    );
    console.log(template, "test------------");
    const TemplateComp = React.cloneElement(template?.template, {
      personalInfo: resume?.personalInfo,
      experiences: resume?.experiences,
      educationInfo: resume?.educationInfo,
      skills: resume?.skills,
      key: resume?.id,
      index: index,
    });
    return TemplateComp;
  };

  const handleDelete = async () => {
    try {
      const response = await handleDeleteResume(deleteResumeId);
      if (response?.success) {
        toast.success(response?.message, {
          position: "top-center",
        });

        getResumeData();
        setDeleteDataMdl(false);
      } else {
        toast.error(response?.error, {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // For deleteing the resume
  const deleteResume = (resume) => {
    setDeleteDataMdl(true);
    setDeleteResumeId(resume?.id);
    // let resumes = window.localStorage.getItem("resumes");
    // let newResumes = JSON.parse(resumes);
    // const newSetOfResumes = newResumes.filter((eachResume) => {
    //   return eachResume.id !== resume.id;
    // });
    // window.localStorage.setItem("resumes", JSON.stringify(newSetOfResumes));
    // setResumes(newSetOfResumes);
  };

  // For Downloading the Resume
  const downloadResume = (id) => {
    const report = new JsPDF("p", "pt", "a4");
    report.html(document.getElementById(`${id}report`)).then(() => {
      report.save(`resume.pdf`);
    });
  };

  const setUserData = (resume) => {
    //for setting personal info
    props.onADDPERSONALINFO(resume.personalInfo);
    //for setting work experience
    props.setADDEXPERIENCEALL(resume.experiences);
    //for setting education information
    props.onADDEDUCATION(resume.educationInfo);
    //for setting skills
    props.onEDITSKILL(resume.skills);
  };

  const navigateToFillDetails = (resume) => {
    props.setSelectedTemplateId(resume?.template_id);
    props.setSelectedResumeId(resume?.id);
    setUserData(resume);
    navigate("/template/FillDetails");
  };

  const getResumeData = async () => {
    setLoading(true)
    try {
      const response = await getResume();
      // const data = [...response, 'info': { id: 3, name: 'Doe' }]
      const updatedArray = response?.map((item) => ({
        educationInfo: item?.education,
        experiences: item?.work_experience,
        skills: item?.skills?.map((val) => val?.skills),
        template_id: item?.template_id,
        id: item?.id,
        personalInfo: {
          address: item?.address,
          country: item?.country?.country_name,
          country_id: item?.country_id,
          city: item?.city?.city_name,
          city_id: item?.city_id,
          email: item?.email,
          firstName: item?.first_name,
          lastName: item?.last_name,
          phone: item?.phone,
          country_code: item?.country_code,
          objective: item?.objective,
          postalCode: item?.postal_code,
          profile: item?.profile,
          state: item?.state?.state_name,
          state_id: item?.state_id,
        },
      }));
      //  console.log("updatedArrayy",updatedArray)
      setResumes(updatedArray);
    } catch (err) {
      console.log(err);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    getResumeData();
  },[]);
  // console.log("resumes", resumes);

  return (
    <>
      {deleteDataMdl && (
        <DeleteResumeWarningMdl
          setDeleteDataMdl={setDeleteDataMdl}
          handleDelete={handleDelete}
        />
      )}
      <div className="my-resumes">
        <h3 className="title">YOUR RESUMES</h3>
        {
        loading ? <div className="d-flex justify-content-center py-3"> <Loader1/> </div> :
        <Box sx={{ flexGrow: 1 }}> 
          <Stack
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: {
                sm: "1fr 1fr ",
                md: "1fr 1fr ",
                lg: "1fr 1fr 1fr",
                xl: "1fr 1fr 1fr",
              },
              gridGap: "30px",
            }}>
            {resumes?.length >= 1 ? (
              resumes?.map((resume, index) => {
                return (
                  <Grid
                    item
                    className={`show-resume`}
                    id={`${index}show-resume`}
                    margin={2}
                    key={index}>
                    <Item className="itemBox" id={`${index}ITEM`}>
                      {getTemplate(resume, index)}
                      <DarkEffect />
                      <div className="use-template-btn-cont">
                        <Button
                          className="use-template-btn"
                          onClick={() => {downloadResume(index)}}
                          size="medium" variant="contained">
                          Download
                        </Button>
                        <Button
                          className="use-template-btn"
                          onClick={()=> {deleteResume(resume)}}
                          size="medium" variant="contained">
                          Delete
                        </Button>
                        <Button
                          className="use-template-btn"
                          onClick={() => navigateToFillDetails(resume)}
                          size="medium" variant="contained">
                          Edit Template
                        </Button>
                      </div>
                    </Item>
                  </Grid>
                );
              })
            ) : (
              <div className="no-resumes-container">
                <SentimentVeryDissatisfiedIcon fontSize="medium" />
                <p className="no-resumes-text">Empty</p>
              </div>
            )}
          </Stack>
        </Box>
      }
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyResumes);
