/* eslint-disable */

import {
  Box,
  Flex,
  HStack,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Select,
  Spinner,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
// import Card from 'components/card/Card';
import Card from "../../../../components_sec/card/Card";
import Menu from "../../../../components_sec/menu/MainMenu";
import React, { useEffect, useState } from "react";
// Assets
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";
import DeleteTableDataWarningMdl from "./DeleteTableDataWarningMdl";
import moment from "moment";
import ReactPaginate from "react-paginate";
import {
  CloneResume,
  handleDeleteResume,
} from "../../../../api/services/memberDashboardService";
import { toast } from "react-toastify";
import { get_ResumeEditData } from "../../../../api/services/resumeService";
import JsPDF from "jspdf";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import {
  SELECTTEMPLATE,
  ADDEXPERIENCEALL,
  ADDEDUCATION,
  ADDPERSONALINFO,
  EDITSKILL,
  SELECTRESUME,
} from "../../../../Redux/Actions";
import { Link } from "react-router-dom";
import { templates } from "../../../../Data/templates";

const columnHelper = createColumnHelper();
// const columns = columnsDataCheck;

const mapStateToProps = (state) => ({
  selectedTemplateId: state.selectedTemplateReducer.selectedTemplateId,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id)),
  setSelectedResumeId: (id) => dispatch(SELECTRESUME(id)),
  onADDPERSONALINFO: (details) => dispatch(ADDPERSONALINFO(details)),
  setADDEXPERIENCEALL: (experiences) => dispatch(ADDEXPERIENCEALL(experiences)),
  onADDEDUCATION: (details) => dispatch(ADDEDUCATION(details)),
  onEDITSKILL: (skills) => dispatch(EDITSKILL(skills)),
});

function MyResumeTable(props) {
  const { tableData, currentPage, setCurrentPage, loading, getDashboard_Data } =
    props;
  const [sorting, setSorting] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDataMdl, setDeleteDataMdl] = useState(false);
  const [selectResumeData, setSelectResumeData] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const navigate = useNavigate();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData?.data;

  const domainName = window.location.hostname; // Get the domain name
  const protocol = window.location.protocol; // Get the protocol (http/https)
  const port = window.location.port; // Get the port (if specified)
  const fullUrl = `${protocol}//${domainName}${port ? `:${port}` : ""}`

  const getTemplate = (template, index) => {
    if (template.id == "") {
      const TemplateComp = React.cloneElement(template.template, {
        personalInfo: props?.personalInfo,
        experiences: props?.experiences,
        educationInfo: props?.educationInfo,
        skills: props?.skills,
        index: index,
        key: template.id,
      });
      return TemplateComp;
    }
  };

  // clone error
  const handleClone_Resume = async (id) => {
    try {
      const response = await CloneResume(id);
      if (response?.success) {
        toast.success(response?.message, {
          position: "top-center",
        });
        getDashboard_Data();
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Delete Resume
  const handleOnDelete = (row) => {
    setDeleteId(row);
    setDeleteDataMdl(true);
  };

  const handleDelete = async () => {
    try {
      const response = await handleDeleteResume(deleteId);
      if (response?.success) {
        toast.success(response?.message, {
          position: "top-center",
        });
        getDashboard_Data();
        setDeleteDataMdl(false);
      } else {
        toast.error(response?.error, {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // For Downloading the Resume
  const downloadResume = (id) => {
    console.log(id);

    // const report = new JsPDF("p", "pt", "a4");
    // report.html(document.getElementById(`${0}report`)).then(() => {
    //     report.save(`resume.pdf`);
    // });
  };

  const setUserData = (resume) => {
    //for setting personal info
    props.onADDPERSONALINFO(resume.personalInfo);

    //for setting work experience
    props.setADDEXPERIENCEALL(resume.experiences);

    //for setting education information
    props.onADDEDUCATION(resume.educationInfo);

    //for setting skills
    props.onEDITSKILL(resume.skills);
  };

  const navigateToFillDetails = (data) => {
    // const updatedResumeData = {
    //   educationInfo: data?.education,
    //   experiences: data?.work_experience,
    //   skills: data?.skills?.map((val) => val?.skills),
    //   template_id: data?.template_id,
    //   id: data?.id,
    //   personalInfo: {
    //     address: data?.address,
    //     country: data?.country?.country_name,
    //     country_id: data?.country_id,
    //     city: data?.city?.city_name,
    //     city_id: data?.city_id,
    //     email: data?.email,
    //     firstName: data?.first_name,
    //     lastName: data?.last_name,
    //     phone: data?.phone,
    //     country_code: data?.country_code,
    //     objective: data?.objective,
    //     postalCode: data?.postal_code,
    //     profile: data?.profile,
    //     state: data?.state?.state_name,
    //     state_id: data?.state_id,
    //   },
    // };
    // props.setSelectedTemplateId(updatedResumeData?.template_id);
    // props.setSelectedResumeId(updatedResumeData?.id);
    // setUserData(updatedResumeData);
    navigate("/MyResume");
  };

  const columns = [
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          First Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("last_name", {
      id: "last_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Last Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("public_link", {
      id: "public_link",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400">
          Public Link
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Link to={`/publiclink/${info?.row?.original?.uuid}`} target="_blank">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue() ? `${fullUrl}/publiclink/${info?.row?.original?.uuid}` : "-"}
            </Text>
          </Link>
        </Flex>
      ),
    }),

    // columnHelper.accessor('view', {
    //   id: 'view',
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: '10px', lg: '12px' }}
    //       color="gray.400"
    //     >
    //       View
    //     </Text>
    //   ),
    //   cell: (info) => (
    //     <Flex align="center">
    //       <Text color={textColor} fontSize="sm" fontWeight="700">
    //         {info.getValue()}
    //       </Text>
    //     </Flex>
    //   ),
    // }),

    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          DATE
        </Text>
      ),
      cell: (info) => {
        // Format the 'created_at' value using moment.js
        const formattedDate = moment(info.getValue()).format("DD MMM YYYY");
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {formattedDate}
          </Text>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400">
          Action
        </Text>
      ),
      cell: (info) => (
        <Box display="flex" gap={2}>
          {/* Flex container for the buttons */}
          <Button
            onClick={() => navigateToFillDetails(info.row.original)} // Handle build button click
            colorScheme="blue"
            size="xs">
            Builder
          </Button>
          {/* <Button
            onClick={() => downloadResume(info.row.original.id)}
            colorScheme="green"
            size="xs">
            Download PDF
          </Button> */}
          <Button
            onClick={() => handleClone_Resume(info.row.original.id)}
            colorScheme="gray"
            size="xs">
            Clone
          </Button>
          {/* <Button
            onClick={() => handleDownloadPDF(info.row.original)}
            colorScheme="teal"
            size="xs">
            Settings
          </Button> */}
          <Button
            onClick={() => handleOnDelete(info.row.original.id)}
            colorScheme="red" size="xs">
            Delete
          </Button>
        </Box>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  // Pagination Logic
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (<>
    <div className="resume-preview-grid-item" id="previewresume">
      {templates?.map((template, index) => {
        return getTemplate(template, index);
      })}
    </div>

    {deleteDataMdl && (
      <DeleteTableDataWarningMdl
        setDeleteDataMdl={setDeleteDataMdl}
        handleDelete={handleDelete}
      />
    )}

    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box>
        {loading ? ( // Show spinner if loading is true
          <Flex justify="center" align="center" minH="200px">
            <Spinner size="lg" /> {/* Loader spinner */}
          </Flex>
        ) : (
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}>
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }} // Font size varies by screen size
                        color="gray.400">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "", // Indicate sort state
                          desc: "", // Placeholder for sorting icons if needed
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel()
                .rows.slice(0, 11).map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        )}
        {/* <div className='text-center'>
          <h4>{Object?.keys(tableData)?.length > 0 ? "" : 'No Data Found'}</h4>
        </div> */}

        <HStack justify="center" spacing={4} mt={4}>
          {/* <Flex px="25px" mb="8px" alignItems="center">
          <Text mr="10px"  mb="0px" p="0px" color={textColor}>
            Show per row:
          </Text>
          <Select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(Number(e.target.value)); // Update rows per page
              // Reset current page to 1 when changing rows per page
              if (currentPage > Math.ceil(paginatedRows.length / Number(e.target.value))) {
                handlePrevPage(); // Go back to the previous page if needed
              }
            }}
            width="100px">
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </Select>
         </Flex> */}

          {tableData?.data?.length < tableData?.total && (
            <div className="pagination-container">
              <ReactPaginate
                pageCount={Math.ceil(tableData?.total / tableData?.per_page)}
                pageRangeDisplayed={1}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="selected"
                disabledClassName="disabled"
              />
            </div>
          )}
        </HStack>
      </Box>
    </Card>
  </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MyResumeTable);
