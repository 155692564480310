// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "../../../../components_sec/card/Card.js";
import React from "react";
import Information from "../../../../views/admin/profile/components/Information";
import moment from "moment/moment.js";

// Assets
export default function GeneralInformation(props) {
  const { rest, ProfileInfo } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card mb={{ base: 1, "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        General Information
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        {ProfileInfo?.description}
      </Text>

      <SimpleGrid
        columns={{ base: 1, sm: 1, md: 2, lg: 2 , xl: 2, "2xl": 4 }} gap="20px">
        <Information
          boxShadow={cardShadow}
          title="Email"
          value={ProfileInfo?.email}
        />

        <Information
          boxShadow={cardShadow}
          title="Phone"
          value={`${ProfileInfo?.country_code}${ProfileInfo?.phone}`}
        />

        {ProfileInfo?.industry && (
          <Information
            boxShadow={cardShadow}
            title="Industry"
            value={ProfileInfo?.industry?.name}
          />
        )}

        {ProfileInfo?.salarymin && (
          <Information
            boxShadow={cardShadow}
            title="Minimum Salary"
            value={ProfileInfo?.salarymin}
          />
        )}

        {ProfileInfo?.salary_rate && (
          <Information
            boxShadow={cardShadow}
            title="Salary Rate"
            value={ProfileInfo?.salary_rate}
          />
        )}

        {ProfileInfo?.country && (
          <Information
            boxShadow={cardShadow}
            title="Country"
            value={ProfileInfo?.country?.country_name}
          />
        )}

        {ProfileInfo?.state && (
          <Information
            boxShadow={cardShadow}
            title="State"
            value={ProfileInfo?.state?.state_name}
          />
        )}

        {ProfileInfo?.city && (
          <Information
            boxShadow={cardShadow}
            title="City"
            value={ProfileInfo?.city?.city_name}
          />
        )}

        {ProfileInfo?.postal_code && (
          <Information
            boxShadow={cardShadow}
            title="Pin Code"
            value={ProfileInfo?.postal_code}
          />
        )}

        {ProfileInfo?.seniority && (
          <Information
            boxShadow={cardShadow}
            title="Seniority"
            value={ProfileInfo?.seniority}
          />
        )}

        {ProfileInfo?.salarymin && (
          <Information
            boxShadow={cardShadow}
            title="Minimum Salary"
            value={ProfileInfo?.salarymin}
          />
        )}

        {ProfileInfo?.web_url && (
          <Information
            boxShadow={cardShadow}
            title="Website URL"
            value={ProfileInfo?.web_url}
          />
        )}
      </SimpleGrid>
    </Card>
  );
}
