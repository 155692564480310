import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../api/services/userService";
import { toast } from 'react-toastify';
import { Tooltip } from "react-tooltip";
import { IoEye, IoEyeOff } from "react-icons/io5";

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();
  const [mobileForm, setMobileForm] = useState({
    dialingcode: "",
    mobilenumber: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [showPassword, setShowPassword] = useState({
    password_chk: false,
    confirm_password_chk: false,
  });


  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.firstname) {
      formErrors.firstname = "First Name is required";
    }

    if (!formData.lastname) {
      formErrors.lastname = "Last Name is required";
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }

    if (!formData.password) {
      formErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      formErrors.password = "Password must be at least 8 characters long.";
    } else if (!/[A-Z]/.test(formData?.password)) {
      formErrors.password = "Password must contain at least one uppercase letter.";
    } else if (!/[a-z]/.test(formData?.password)) {
      formErrors.password = "Password must contain at least one lowercase letter.";
    } else if (!/[0-9]/.test(formData?.password)) {
      formErrors.password = "Password must contain at least one number.";
    } else if (!/[!@#$%^&*]/.test(formData?.password)) {
      formErrors.password = "Password must contain at least one special character.";
    }

    if (!formData.confirm_password) {
      formErrors.confirm_password = "Confirm password is required";
    } else if (formData.password !== formData.confirm_password) {
      formErrors.confirm_password = "Passwords do not match";
    }

    if (!mobileForm.mobilenumber) {
      formErrors.mobileNumber = 'Mobile number is required';
    } else if (isNaN(mobileForm.mobilenumber)) {
      formErrors.mobileNumber = 'Invalid mobile number, should contain only numbers';
    } else if (mobileForm.mobilenumber.toString().length < 10) {
      formErrors.mobileNumber = 'Mobile number should be at least 10 digits';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const fData = {
        f_name: formData?.firstname,
        l_name: formData?.lastname,
        email: formData?.email,
        password: formData?.password,
        c_password: formData?.confirm_password,
        country_code: mobileForm?.dialingcode,
        phone: mobileForm?.mobilenumber,
      }
      try {
        setBtnLoading(true)
        const response = await register(fData)
        console.log("Form submitted successfully:", response);
        const token = response?.token;
        const message = response?.message;
        const errorMessage = response.errors;

        for (const field in errorMessage) {
          if (errorMessage.hasOwnProperty(field)) {
            errorMessage[field].forEach((message) => {
              toast.error(`${message}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                theme: "light",
              });
            });
          }
        }
        if (token) {
          localStorage.setItem("LoginToken", token);
          toast.success(message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
          navigate(`/`);
          setFormData({
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            confirm_password: "",
          });
          setMobileForm({
            dialingcode: +91,
            mobilenumber: "",
          })
        }
      } catch (error) {

        if (error?.response?.data?.errors) {
          const { errors } = error.response.data;

          Object.keys(errors).forEach((key) => {
            errors[key].forEach((message) => {
              toast.error(message, {
                position: "top-center",
                autoClose: 3000,
              });
            });
          });
        }

      } finally {
        setBtnLoading(false)
      }
    }
  };

  const handlePhoneChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setMobileForm({
      dialingcode: d_code,
      mobilenumber: parseInt(phoneNum),
    });
  };

  return (
    <>
      <Tooltip id="password-example-tooltip" className="tooltip_style" >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <span className="tooltip-password-head">Password must contain:</span>
          <span className="mt-2">-At least one uppercase letter </span>
          <span>-At least one lowercase letter</span>
          <span>-At least one special character</span>
          <span>-At least one number</span>
          <span>-Be at least 8 characters</span>
          {/* <span>-Your password must not contain more than 2 consecutive identical characters.</span> */}
        </div>
      </Tooltip>

      <div className="right-login-form">
        <h3>Create your Account</h3>
        <p>To create an account please fill the details.</p>
        <form onSubmit={handleSubmit}>
          <div className="form-outline mb-3">
            <input
              type="text"
              className="form-control"
              name="firstname"
              placeholder="First Name"
              value={formData?.firstname}
              onChange={handleChange}
            />
            {errors?.firstname && (
              <span className="mt-1" style={{ color: "red", fontSize: "13px" }}>
                {errors?.firstname}
              </span>
            )}
          </div>

          <div className="form-outline mb-3">
            <input
              type="text"
              className="form-control"
              name="lastname"
              placeholder="Last Name"
              value={formData?.lastname}
              onChange={handleChange}
            />
            {errors?.lastname && (
              <span className="mt-1" style={{ color: "red", fontSize: "13px" }}>
                {errors?.lastname}
              </span>
            )}
          </div>

          <div className="form-outline mb-3">
            {/* <label className="form-label" for="form2Example11">Email Address</label> */}
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Email Address"
              value={formData?.email}
              onChange={handleChange}
            />
            {errors?.email && (
              <span className="mt-1" style={{ color: "red", fontSize: "13px" }}>
                {errors?.email}
              </span>
            )}
          </div>

          <div className="form-outline mb-3">
            <PhoneInput inputClass="custom-phone-input" country={"in"} onChange={handlePhoneChange} />
            {errors?.mobileNumber && (
              <span className="mt-1" style={{ color: "red", fontSize: "13px" }}>
                {errors?.mobileNumber}
              </span>
            )}
          </div>

          <div className="form-outline mb-3 position-relative">
            {/* <label className="form-label" >Password</label> */}
            <input
              type={showPassword?.password_chk ? "text" : "password"}
              name="password"
              data-tooltip-id="password-example-tooltip"
              placeholder="Password"
              className="form-control"
              value={formData?.password}
              onChange={handleChange}
            />
            <div className="position-absolute" style={{ top: 10, right: 10 }}>
              <button type="button" onClick={() => togglePasswordVisibility("password_chk")}>
                {showPassword?.password_chk ?
                  <IoEyeOff size={25} />
                  :
                  <IoEye size={25} />
                }
              </button>
            </div>

            {errors?.password && (
              <span className="mt-1" style={{ color: "red", fontSize: "13px" }}>
                {errors?.password}
              </span>
            )}
          </div>

          <div className="form-outline mb-3 position-relative">
            {/* <label className="form-label" >Password</label> */}
            <input
              type={showPassword?.confirm_password_chk ? "text" : "password"}
              name="confirm_password"
              placeholder="Confirm Password"
              className="form-control"
              value={formData?.confirm_password}
              onChange={handleChange}
            />

            <div className="position-absolute" style={{ top: 10, right: 10 }}>
              <button type="button" onClick={() => togglePasswordVisibility("confirm_password_chk")}>
                {showPassword?.confirm_password_chk ?
                  <IoEyeOff size={25} />
                  :
                  <IoEye size={25} />
                }
              </button>
            </div>

            {errors?.confirm_password && (
              <span className="mt-1" style={{ color: "red", fontSize: "13px" }}>
                {errors?.confirm_password}
              </span>
            )}
          </div>

          <div className="d-flex flex-column text-center pt-1 mb-5 pb-1">
            <button className="d-flex gap-1 align-items-center justify-content-center login-signup-btn" type="submit">
              {
                btnLoading &&
                <div className="spinner-border text-light" style={{ height: 15, width: 15 }}
                  role="status"> </div>
              }
              Register
            </button>
          </div>

          <div className="d-flex gap-2 align-items-center justify-content-center pb-4 ">
            <p className="already-have-an-acco m-0">Already have an account?</p>
            <Link to="/login" className="register-btn">
              Login
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegisterForm;
