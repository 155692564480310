import { Link } from "react-router-dom";

const AllBlog = () => {
    return (
        <div className="container news-section-3 py-4">
            <div className="d-flex justify-content-between">
                <h4>WEEKLY REVIEW BUSINESS</h4>
                <p className="view-all-link" onClick={() => window.history.back()}
                    style={{ textDecoration: 'none', color: '#000', fontSize: '16px', cursor: 'pointer' }}
                    onMouseEnter={(e) => e.target.style.color = '#ff5733'}
                    onMouseLeave={(e) => e.target.style.color = '#000'}
                >Go Back</p>
            </div>

            <div className="row">
                <div className="col-md-4 py-3 mb-5">
                    <div className="weekly-review">
                        <img
                            className=""
                            src="https://s3.resume.io/cdn-cgi/image/width=304,height=266,dpr=1.5,fit=contain,quality=100,format=auto/uploads/blog_post/featured_image/299/ResumeFormats.jpg"
                            style={{ height: '250px', width: '100%', objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                        />
                        <div className="review-text-main px-2 py-2" style={{ borderRadius: '0px 0px 10px 10px' }}>
                            <div className="review-text-1 py-2">
                                <p className="m-0">LIFESTYLE</p>
                                <p className="m-0">2 MIN TO READ</p>
                            </div>
                            <p className="review-text-2 p-0 m-0">
                                Look Of The Week: Blackpink Headline Coachella In Korean Hanboks
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AllBlog;
