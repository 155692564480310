
import { useEffect, useState } from "react";
import {
    FacebookIcon,
    WhatsappIcon,
    EmailIcon,
    PinterestIcon,
    TwitterIcon,
    LinkedinIcon
} from "react-share";
import axios from "axios";
import "../../../src/assets/Styles/BlogDetails.css";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

const BlogDetails = () => {
    const parmas = useParams()?.id;
    const navigate = useNavigate();
    const [blog, setBlog] = useState({});

    const fetchBlogDetails = async () => {
        try {
            const response = await axios.get(`https://resumecv.idea2reality.tech/api/details/${parmas}`);
            setBlog(response?.data);
        } catch (error) {
            console.error("Error fetching blog details:", error);
        }
    };


    useEffect(() => {
        fetchBlogDetails();
    }, []);


    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };


    return (
        <div className="container mt-5">
            <div className="row mb-5">
                {/* News Detail Section */}
                <div className="col-lg-8 col-md-7">
                    <div className="news-detail">
                        <h1 className="display-6">{blog?.data?.title}</h1>
                        <p className="text-muted">Published on {formatDate(blog?.data?.created_at)}</p>
                        <p className="lead" dangerouslySetInnerHTML={{ __html: blog?.data?.summary }}>
                        </p>
                        <div className="mb-3 d-flex gap-1">
                            <FacebookIcon size={22} round={true} />
                            <WhatsappIcon size={22} round={true} />
                            <TwitterIcon size={22} round={true} />
                            <LinkedinIcon size={22} round={true} />
                            <EmailIcon size={22} round={true} />
                            <PinterestIcon size={22} round={true} />
                        </div>


                        <img
                            src={`https://resumecv.idea2reality.tech/storage/thumbnail_image/${blog?.data?.thumbnail_image
                                }`}
                            className="img-fluid mb-4"
                            alt="News Image"
                            style={{ width: '100%', height: '320px' }}
                        />
                        <p className="" dangerouslySetInnerHTML={{ __html: blog?.data?.description }}>
                        </p>
                        <p>
                            Phasellus viverra est in lectus pretium fermentum. Aliquam erat
                            volutpat. Mauris eget urna non urna suscipit scelerisque.
                        </p>
                    </div>
                </div>

                {/* Related News or Categories Sidebar */}
                <div className="col-lg-4 col-md-5">
                    <div className="sidebar">
                        <h3>Categories</h3>
                        <ul className="list-group">

                            {blog?.category?.map((item) => (
                                <li className="list-group-item" onClick={() => navigate(`/category-blog/${item?.id}`)}>
                                    <a href="#">{item?.name}</a>
                                    <span>{'>'}</span>
                                </li>
                            ))}

                            <Link to="#" className="d-flex justify-content-end"
                                style={{ textDecoration: 'none', color: '#000', fontSize: '16px' }}
                                onMouseEnter={(e) => e.target.style.color = '#ff5733'}
                                onMouseLeave={(e) => e.target.style.color = '#000'}
                            >See More...</Link>
                        </ul>


                        <h3 className="mt-4">Related News</h3>
                        <ul className="list-group">
                            {blog?.related?.map((value) => {
                                return (<>
                                    {value?.id == parmas ? "No Data Available" :
                                        <li className="list-group-item">
                                            <a href="#">
                                                <div className="d-flex gap-3">
                                                    <img src={`https://resumecv.idea2reality.tech/storage/thumbnail_image/${value?.thumbnail_image
                                                        }`} height="80px" width="80px" style={{ objectFit: "cover" }} />
                                                    <div>
                                                        {value?.title}
                                                        <p>{formatDate(blog?.data?.created_at)}</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <span>{'>'}</span>
                                        </li>
                                    }
                                </>)
                            })}

                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default BlogDetails;








