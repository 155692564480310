import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const CategoryBlog = () => {
    const parmas = useParams()?.id;
    const [category, setCategory] = useState([]);
    console.log(category)


    const fetchBlogCategoriesDetails = async () => {
        try {
            const response = await axios.get(`https://resumecv.idea2reality.tech/api/category_according_blogs/${parmas}`);
            setCategory(response?.data?.data);
        } catch (error) {
            console.error("Error fetching blog details:", error);
        }
    };


    useEffect(() => {
        fetchBlogCategoriesDetails();
    }, []);



    return (
        <div className="container news-section-3 py-4">
            <div className="d-flex justify-content-between">
                <h4>Category Related blogs</h4>
                <p className="view-all-link" onClick={() => window.history.back()}
                    style={{ textDecoration: 'none', color: '#000', fontSize: '16px', cursor: 'pointer' }}
                    onMouseEnter={(e) => e.target.style.color = '#ff5733'}
                    onMouseLeave={(e) => e.target.style.color = '#000'}
                >Go Back</p>
            </div>

            <div className="row">

                {
                    category?.length > 0 ?
                        category?.map((item) => (
                            <div className="col-md-4 py-3 mb-5">
                                <div className="weekly-review">
                                    <img
                                        className=""
                                        src="https://s3.resume.io/cdn-cgi/image/width=304,height=266,dpr=1.5,fit=contain,quality=100,format=auto/uploads/blog_post/featured_image/299/ResumeFormats.jpg"
                                        style={{ height: '250px', width: '100%', objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                                    />
                                    <div className="review-text-main px-2 py-2" style={{ borderRadius: '0px 0px 10px 10px' }}>
                                        <div className="review-text-1 py-2">
                                            <p className="m-0">{item?.category?.name}</p>
                                            <p className="m-0">2 MIN TO READ</p>
                                        </div>
                                        <p className="review-text-2 p-0 m-0">
                                            {item?.title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <h3 className="text-center my-5" style={{ color: '#951dc1', fontWeight: 'bold' }}>No results found</h3>
                }

            </div>

        </div>
    );
};

export default CategoryBlog;
