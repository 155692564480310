import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../api/services/userService";
import { toast } from 'react-toastify';
import { IoEye, IoEyeOff } from "react-icons/io5";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    // rememberMe: true,
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [passWordBtn, setPassWordBtn] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }

    if (!formData.password) {
      formErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      formErrors.password = "Password must be at least 6 characters";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setBtnLoading(true);
      try {
        const response = await login(formData);
        const token = response?.token;
        const message = response?.message;
        const errorMessage = response?.errors;
        if (token) {
          localStorage.setItem("LoginToken", token);
          toast.success(message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
          navigate(`/`);
          setFormData({
            email: "",
            password: "",
          })
        } else {
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.errors, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "light",
        });
      } finally {
        setBtnLoading(false);
      }
    }
  };

  return (
    <>
      <div className="right-login-form">
        <h3>Login</h3>
        <p>Please enter your credentials to log in.</p>
        <form onSubmit={handleSubmit}>
          <div className="form-outline mb-3">
            {/* <label className="form-label" for="form2Example11">Email Address</label> */}
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Email Address"
              value={formData?.email}
              onChange={handleChange}
            />

            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>

          <div className="form-outline mb-3 position-relative">
            {/* <label className="form-label" >Password</label> */}
            <input
              type={passWordBtn ? "text" : 'password'}
              name="password"
              placeholder="Password"
              className="form-control"
              value={formData?.password}
              onChange={handleChange}
            />

            <div className="position-absolute" style={{ top: 10, right: 10 }}>
              <button type="button" onClick={() => setPassWordBtn(!passWordBtn)}>
                {passWordBtn ?
                  <IoEyeOff size={25} />
                  :
                  <IoEye size={25} />
                }
              </button>
            </div>

            {errors.password && (
              <p className="text-danger">{errors.password}</p>
            )}
          </div>

          <div className="d-flex justify-content-between mb-3">
            {/* <div className="form-check">
                    <input className="form-check-input" 
                     type="checkbox"
                     name="rememberMe"
                     checked={formData?.rememberMe}
                     onChange={handleChange}/>
                    <label className="form-check-label"> Remember me </label>
                   </div> */}
            <div>
              <Link to="/forgotpassword" className="register-btn">
                Forgot password?
              </Link>
            </div>
          </div>

          <div className="d-flex flex-column text-center pt-1 mb-5 pb-1">
            <button className="login-signup-btn" type="submit">
              {btnLoading && (
                <div
                  className="spinner-border text-light"
                  style={{ height: 15, width: 15 }}
                  role="status"
                >
                  {" "}
                </div>
              )}
              Log in
            </button>
          </div>

          <div className="d-flex gap-2 align-items-center justify-content-center pb-4 ">
            <p className="already-have-an-acco m-0">Don't have account yet?</p>
            <Link to="/register" className="register-btn">
              Register
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
