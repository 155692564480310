import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/Styles/ForgotPassword.css";
import ForgotWithEmail from "../NewComponents/ForgotPassword/ForgotWithEmail";
import ForgotWithMobile from "../NewComponents/ForgotPassword/ForgotWithMobile";
import NewPasswordForm from "../NewComponents/ForgotPassword/NewPasswordForm";
import Navlogo from "../assets/Images/NavLogo.png";
import { forget_VerifyOtp } from "../api/services/userService";
import { toast } from "react-toastify";
import LoginRegisterHeader from "../Components/NewNavbar/LoginRegisterHeader";

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [mobileForm, setMobileForm] = useState({
    dialingcode: "",
    mobilenumber: "",
  });
  // OTP seaction state
  const [otpSection, setOtpSection] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [forgotOption, setForgotOption] = useState("email");
  //new pass seaction state
  const [newPasswordSection, setNewPasswordSection] = useState(false);

  const [emailOtp, setEmailOtp] = useState("");
  const [verifiedEmailOtp, setVerifiedEmailOtp] = useState(false);
  const [verifyBtnLoading, setVerifyBtnLoading] = useState(false);
  const [phoneOtp, setPhoneOtp] = useState("");


  const validateForm = () => {
    let valid = true;
    let newErrors = {}
    if (!emailOtp) {
      newErrors.otp = 'Otp is required';
      valid = false;
    } else if (emailOtp.toString().length < 6) {
      newErrors.otp = 'Otp should be at least 6 digits';
      valid = false;
    }

    setOtpError(newErrors);
    return valid;
  };

  const VerifyEmail = async (e) => {
    e.preventDefault();
    const fData = {
      email: email,
      otp: emailOtp
    }
    if (validateForm()) {
      try {
        setVerifyBtnLoading(true)
        const response = await forget_VerifyOtp(fData);
        if (response?.data?.success) {
          toast.success(response?.data?.message, {
            position: "top-center",
          });
          setVerifiedEmailOtp(true);
          setNewPasswordSection(true);
          setOtpSection(false)
        } else {
          toast.error(response?.data?.message, {
            position: "top-center",
          });
        }

      } catch (error) {
        console.log("err", error)
      } finally {
        setVerifyBtnLoading(false)
      }
    }
  };

  const handleOtpChange = (e) => {
    const otpValue = e.target.value;
    const regex = /^\d{0,6}$/;
    if (regex.test(otpValue)) {
      if (forgotOption == 'email') {
        setEmailOtp(otpValue);
      } else {
        setPhoneOtp(otpValue)
      }
    }
  }

  console.log("otpError", otpError)

  return (
    <>
      {/* <LoginRegisterHeader /> */}
      <div className="login-section">
        <div className="container position-relative">
          {/* <div>
            <Link to="/" className="navlogo-signinpage">
              <img className="mt-0" style={{ height: 20 }}
                src={Navlogo}
                // src="https://resume.idea2reality.tech/storage/system/ks47jwcmtBZwKefDiC1Wiq6ydTMid9epJdaDkTii.png" 
                alt="logo" />
            </Link>
          </div> */}
          <div className="row align-items-center" style={{ height: "100vh" }}>
            <div className="col-12 col-lg-6 d-none d-lg-block">
              <div className="login-left-section mt-4">
                <h3>Start your journey with us.</h3>
                <p>Discover the world’s best community of resume creators and cover pages..</p>
              </div>
            </div>

            <div className="col-12 col-lg-6 ">
              <section className="right-login-form">
                <div className="d-table1">
                  <div className="container-fluid">
                    <div className="forgotpassword-form ">
                      <div className="d-flex justify-content-between mb-2">

                        {otpSection ? (
                          <h3>Enter Your OTP</h3>
                        ) : newPasswordSection ?
                          <h3>Enter New Password</h3> : (
                            <h3>Forgot Your Password</h3>
                          )}
                      </div>

                      {/* <div className="d-flex gap-4">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="email"
                          disabled={otpSection || newPasswordSection}
                          checked={forgotOption == "email"}
                          name="forgotOption"
                          onChange={(e) => setForgotOption(e?.target?.value)}
                        />
                        Email</label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="mobile"
                          disabled={otpSection || newPasswordSection}
                          checked={forgotOption == "mobile"}
                          name="forgotOption"
                          onChange={(e) => setForgotOption(e?.target?.value)}
                        />
                        Mobile</label>
                    </div>
                  </div> */}

                      <div className="">
                        <div className="">
                          {otpSection ?
                            <>
                              <div className="">
                                {/* <label className="login-label mb-0">
               OTP <span className="asterisk">*</span>
              </label> */}
                                <div className="position-relative w-100 mt-2">
                                  <input
                                    type="text"
                                    value={forgotOption == 'email' ? emailOtp : phoneOtp}
                                    name="emailOtp"
                                    className="form-control w-100"
                                    placeholder="OTP"
                                    onChange={handleOtpChange}
                                  />
                                  {otpError?.otp && <span className="" style={{ color: 'red', fontSize: '13px' }}>{otpError?.otp}</span>}
                                  <div>
                                    <button className="otp-verify-btn mt-3" type="button" onClick={VerifyEmail}>
                                      {verifyBtnLoading &&
                                        <div className="spinner-border text-light" style={{ height: 15, width: 15 }}
                                          role="status"> </div>
                                      }
                                      Verify
                                    </button>
                                  </div>
                                </div>
                                <button className="register-btn mt-4" onClick={() => setOtpSection(false)}>Back To Login </button>

                              </div>
                            </>
                            :
                            newPasswordSection ?
                              <NewPasswordForm navigate={navigate} setOtpSection={setOtpSection} forgotOption={forgotOption} email={email} mobileForm={mobileForm} />
                              :
                              forgotOption == "email" ?
                                <ForgotWithEmail setOtpSection={setOtpSection} setEmailOtp={setEmailOtp} email={email} setEmail={setEmail} />
                                :
                                <ForgotWithMobile mobileForm={mobileForm} setPhoneOtp={setPhoneOtp} setMobileForm={setMobileForm} setOtpSection={setOtpSection} />
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default ForgotPassword;
