import React, { useState, useEffect } from 'react';
import "../assets/Styles/fillDetailsSidebar.css"
import { Divider, List, ListItemText, ListItem, ListItemButton } from '@mui/material';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import WorkHistoryRoundedIcon from "@mui/icons-material/WorkHistoryRounded";
import IconButton from "@mui/material/IconButton";







let ItemHeight = 50;

const FillDetailsSidebar = (props) => {


    // For Getting the Window size
    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);





    //To add the onClick event in our sideBar
    function PersonalInfo() {
        props.setTab(0);
    }

    function WorkExperience() {
        props.setTab(1)
    }

    function Education() {
        props.setTab(2)
    }

    function keySkill() {
        props.setTab(3)
    }


    return (
        // <div className='col-3'>
        //     {windowSize.innerWidth > 800 ? (
        //         <Box
        //             sx={{
        //                 width: "100%",
        //                 maxWidth: 300,
        //                 boxShadow: "0px 0px 4px 0px rgb(229, 229, 229)",
        //                 height: "fit-content",
        //             }}>
        //             <List disablePadding>
        //                 <ListItem disablePadding>
        //                     <ListItemButton
        //                         component="a"
        //                         sx={
        //                             props.tab === 0
        //                                ? { borderLeft: "3px solid #951dc1" }
        //                                : null
        //                         }>
        //                         <AccountCircleOutlinedIcon
        //                             sx={
        //                                 props.tab === 0
        //                                     ? { color: "#951dc1" }
        //                                     : null
        //                             }
        //                         />
        //                         <ListItemText
        //                             className="Items"
        //                             onClick={PersonalInfo}
        //                             primary="Personal Info"
        //                             sx={
        //                                 props.tab === 0
        //                                     ? { color: "#951dc1", paddingLeft: "8px" }
        //                                     : null
        //                             }
        //                         />
        //                     </ListItemButton>
        //                 </ListItem>
        //                 <Divider />
        //                 <ListItem disablePadding>
        //                     <ListItemButton
        //                         component="a"
        //                         sx={
        //                             props.tab === 1
        //                                 ? { borderLeft: "3px solid #951dc1" }
        //                                 : null
        //                         }>
        //                         <WorkHistoryRoundedIcon
        //                             sx={
        //                                 props.tab === 1
        //                                     ? { color: "#951dc1" }
        //                                     : null
        //                             }
        //                         />
        //                         <ListItemText
        //                             className="Items"
        //                             onClick={WorkExperience}
        //                             primary="Work Experience"
        //                             sx={
        //                                 props.tab === 1
        //                                     ? { color: "#951dc1", paddingLeft: "8px" }
        //                                     : null
        //                             }
        //                         />
        //                     </ListItemButton>
        //                 </ListItem>
        //                 <ListItem disablePadding>
        //                     <ListItemButton
        //                         component="a"
        //                         sx={
        //                             props.tab === 2
        //                                 ? { borderLeft: "3px solid #951dc1" }
        //                                 : null
        //                         }>
        //                         <SchoolRoundedIcon
        //                             sx={
        //                                 props.tab === 2
        //                                     ? { color: "#951dc1" }
        //                                     : null
        //                             }
        //                         />
        //                         <ListItemText
        //                             className="Items"
        //                             onClick={Education}
        //                             primary="Education"
        //                             sx={
        //                                 props.tab === 2
        //                                     ? { color: "#951dc1", paddingLeft: "8px" }
        //                                     : null
        //                             }
        //                         />
        //                     </ListItemButton>
        //                 </ListItem>
        //                 <Divider />
        //                 <ListItem disablePadding>
        //                     <ListItemButton
        //                         component="a"
        //                         sx={
        //                             props.tab === 3
        //                                 ? { borderLeft: "3px solid #951dc1" }
        //                                 : null
        //                         }>
        //                         <StarsRoundedIcon
        //                              sx={
        //                                 props.tab === 3
        //                                     ? { color: "#951dc1" }
        //                                     : null
        //                             }
        //                         />
        //                         <ListItemText
        //                             className="Items"
        //                             onClick={keySkill}
        //                             primary="Key Skills"
        //                             sx={
        //                                 props.tab === 3
        //                                     ? { color: "#951dc1", paddingLeft: "8px" }
        //                                     : null
        //                             }
        //                         />
        //                     </ListItemButton>
        //                 </ListItem>
        //             </List>
        //         </Box>
        //     ) : (
        //         <div >
        //             <IconButton
        //                 aria-label="more"
        //                 id="long-button"
        //                 aria-controls={open ? "long-menu" : undefined}
        //                 aria-expanded={open ? "true" : undefined}
        //                 aria-haspopup="true"
        //                 onClick={handleClick}>
        //                 <MoreVertIcon />
        //             </IconButton>
        //             <Menu
        //                 id="long-menu"
        //                 MenuListProps={{
        //                     "aria-labelledby": "long-button",
        //                 }}
        //                 anchorEl={anchorEl}
        //                 open={open}
        //                 onClose={handleClose}
        //                 PaperProps={{
        //                     style: {
        //                         maxHeight: ItemHeight * 4.5,
        //                         width: "20ch",
        //                     },
        //                 }}>
        //                 <MenuItem
        //                     sx={props.tab === 0 ? { color: "rgb(0, 128, 255)" } : null}
        //                     onClick={() => {
        //                         handleClose();
        //                     }}>
        //                     <AccountCircleOutlinedIcon
        //                         color={props.tab === 0 ? "info" : "disabled"}
        //                     />
        //                     <ListItemText
        //                         onClick={PersonalInfo}
        //                         className="Items"
        //                         primary="Personal Info"
        //                         sx={
        //                             props.tab === 0
        //                                 ? { color: "rgb(0, 128, 255)", paddingLeft: "8px" }
        //                                 : null
        //                         }
        //                     />
        //                 </MenuItem>
        //                 <Divider />
        //                 <MenuItem
        //                     sx={props.tab === 1 ? { color: "rgb(0, 128, 255)" } : null}
        //                     onClick={() => {
        //                         handleClose();
        //                     }}>
        //                     <WorkHistoryRoundedIcon
        //                         color={props.tab === 1 ? "info" : "disabled"}
        //                     />
        //                     <ListItemText
        //                         className="Items"
        //                         onClick={WorkExperience}
        //                         primary="Work Experience"
        //                         sx={
        //                             props.tab === 1
        //                                 ? { color: "rgb(0, 128, 255)", paddingLeft: "8px" }
        //                                 : null
        //                         }
        //                     />
        //                 </MenuItem>
        //                 <Divider />
        //                 <MenuItem
        //                     sx={props.tab === 2 ? { color: "rgb(0, 128, 255)" } : null}
        //                     onClick={() => {
        //                         handleClose();
        //                     }}>
        //                     <SchoolRoundedIcon
        //                         color={props.tab === 2 ? "info" : "disabled"}
        //                     />
        //                     <ListItemText
        //                         className="Items"
        //                         onClick={Education}
        //                         primary="Education"
        //                         sx={
        //                             props.tab === 2
        //                                 ? { color: "rgb(0, 128, 255)", paddingLeft: "8px" }
        //                                 : null
        //                         }
        //                     />
        //                 </MenuItem>
        //                 <Divider />
        //                 <MenuItem
        //                     sx={props.tab === 3 ? { color: "rgb(0, 128, 255)" } : null}
        //                     onClick={() => {
        //                         handleClose();
        //                     }}>
        //                     <StarsRoundedIcon color={props.tab === 3 ? "info" : "disabled"} />
        //                     <ListItemText
        //                         className="Items"
        //                         onClick={keySkill}
        //                         primary="Key Skills"
        //                         sx={
        //                             props.tab === 3
        //                                 ? { color: "rgb(0, 128, 255)", paddingLeft: "8px" }
        //                                 : null
        //                         }
        //                     />
        //                 </MenuItem>
        //             </Menu>
        //         </div>
        //     )}
        // </div>

        <>

            <div className="row mb-3 mt-3 mt-md-5 mx-2 mx-md-5">
                <div className="tab-container">
                    <button
                        className={`${props.tab === 0 ? "create-event-active-tab" : "create-event-tab"}`}
                        onClick={() => props.setTab(0)}
                    >
                        Personal Info
                    </button>
                    <button
                        className={`${props.tab === 1 ? "create-event-active-tab" : "create-event-tab"}`}
                        onClick={() => props.setTab(1)}
                    >
                        Work Experience
                    </button>
                    <button
                        className={`${props.tab === 2 ? "create-event-active-tab" : "create-event-tab"}`}
                        onClick={() => props.setTab(2)}
                    >
                        Education
                    </button>
                    <button
                        className={`${props.tab === 3 ? "create-event-active-tab" : "create-event-tab"}`}
                        onClick={() => props.setTab(3)}
                    >
                        Key Skill
                    </button>

                </div>
            </div>

        </>
    );
}

export default FillDetailsSidebar